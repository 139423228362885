<template>
  <!--  人脸录入系统-->
  <div>
    <el-row type="flex" justify="space-around" class="top">
      <div>
        <video id="video" autoplay playsinline></video>
        <span>摄像头</span>
      </div>
      <div>
        <canvas id="canvas" width="400" height="300"></canvas>
        <span>照片</span>
      </div>
    </el-row>
    <el-row type="flex" justify="space-around">
      <el-button type="danger" icon="el-icon-thumb" size="medium" @click="picture">拍照</el-button>
      <el-button type="primary" icon="el-icon-s-promotion" size="medium" @click="add">上传</el-button>
    </el-row>
  </div>

</template>

<script>
// import $ from "jquery"
import {addFace} from "@/api/user";

export default {


  name: "FaceAdd",
  data() {
    return {
      stream: 0
    }
  },
  mounted() {
    // 获取摄像头信息
    this.getCamera();
  },
  beforeDestroy() {
    console.log("开始关闭摄像头")
    console.log(this.stream)
    if (this.stream !== 0) {
      this.stream.getTracks().forEach(function (track) {
        track.stop();
      })
    }
  },
  methods: {
    async getCamera() {
      // 获取摄像头，不需要音响
      let constraints = {
        video: true,
        audio: false
      };
      // 声音太响，还是改成false
      this.stream = await navigator.mediaDevices.getUserMedia(constraints).catch(error => {
        this.$message({
          type: "error",
          message: "对不起你的摄像头可能打不开",
          duration: 2 * 1000
        });
      })
      document.getElementById("video").srcObject = this.stream;
    },
    picture() {
      let video = document.getElementById('video');
      // 获取画布
      let canvas = document.getElementById('canvas');
      // 设置为2d
      let ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, 400, 300);
    },

    dataURLtoFile(dataurl, filename) {//将base64转换为文件，dataurl为base64字符串，filename为文件名（必须带后缀名，如.jpg,.png）
      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      this.fileList = new File([u8arr], filename, {type: mime});
      return new File([u8arr], filename, {type: mime});
    },
    /**
     * 上传图片
     */
    add() {
      // 获取视屏源
      let video = document.getElementById('video');
      // 获取画布
      let canvas = document.getElementById('canvas');
      // 设置为2d
      let ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, 400, 300);
      // toDataURL  ---  可传入'image/png'---默认, 'image/jpeg'
      // 保存为图片
      let img = document.getElementById('canvas').toDataURL();
      let form = new FormData();
      form.append("file", this.dataURLtoFile(img, 'face.png'));
      form.append("userId", this.$store.state.user.name)

      // 添加人脸识别信息
      addFace(form).then(res => {
        this.$message(res)
      }).catch(error => {
        // 输出错误信息
        this.$message(error.message);
      })
    }
  }
}
</script>

<style scoped>
#video {
  height: 18.75rem;
  width: 18.75rem;
  border: 1px solid red;
}

#canvas {

  border: 1px solid red;
}

.top {
  margin-bottom: 30px;
}
</style>
